const contact = [


    {
        nombre: 'Stech Dev',
        telefono: 543400524168,
        email: 'info@stechdev.com',
        direccion: 'Santa Fe, Argentina',
        instagram: 'https://www.instagram.com/stech.solucion.tecnologica/',
        facebook: 'https://www.facebook.com/soluciontecnologica.vc',
        linkedin: 'https://www.linkedin.com/company/stechdev'

    }

]
export default contact