import React from 'react';
import './Footer.css';
import { Link as Anchor } from 'react-router-dom';
import logo from '../../images/logo.png';
import contactData from '../contact';
export default function Footer() {
    const contact = contactData[0];

    return (
        <div className='FooterContain'>
            <div className='footerText'>
                <img src={logo} alt="En catálogo web logo" />

                <div className='socials'>
                    <Anchor to={contact.instagram} target="_blank"><i className='fa fa-instagram'></i></Anchor>
                    <Anchor to={contact.linkedin} target="_blank"><i className='fa fa-linkedin'></i></Anchor>
                    <Anchor to={contact.facebook} target="_blank"><i className='fa fa-facebook'></i></Anchor>
                </div>
            </div>
            <div className='footerText'>
                <h3>Contacto</h3>
                <Anchor to={`mailto:${contact.email}`} target="_blank">{contact.email}</Anchor>
                <Anchor to={`tel:${contact.telefono}`} target="_blank">{contact.telefono}</Anchor>
                <Anchor to={`https://www.google.com/maps?q=${encodeURIComponent(contact.direccion)}`} target="_blank">{contact.direccion}</Anchor>
            </div>
            <div className='footerText'>
                <h3>Nosotros</h3>
                <p>
                    Estaremos realmente atrapados con la tecnología cuando todo lo que realmente queramos sean sólo cosas que funcionen.</p>

            </div>

        </div>
    );
}
