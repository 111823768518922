import React from 'react'
import './About.css'
import logo from '../../images/logo.png'
import img from '../../images/about.png'
import img2 from '../../images/about2.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck } from '@fortawesome/free-solid-svg-icons'
import { Link as Anchor } from 'react-router-dom';
export default function About() {
    return (
        <div className='aboutContain'>
            <div className='aboutText'>
                {/* <img src={logo} alt="imagen" className='imgSec' /> */}
                <h2 className='titleSec'>Sobre Nosotros</h2>
                <p>
                    Somos una StartUp joven que viene a revolucionar el mercado, con opciones tecnológicas, para todo aquel emprendedor que quiera llevar su emprendimiento a otro nivel pueda hacerlo sin limites. Y como dice nuestro eslogan, la tecnología nos atrapa cuando vemos que todo funciona.
                </p>

                <span>¡Únete a nosotros en este viaje hacia la eficiencia y el éxito!</span>
                <Anchor to={`https://docs.google.com/forms/d/e/1FAIpQLSdZS9USJkzdgolRuhoMQnPaq5Asaj0b9oiftJOKWgJ63g369w/viewform`} >
                    Unirme al equipo
                </Anchor>

            </div>
            <hr className='hrSection' />
            <h2 className='titleSec2'>Quickorder </h2>
            <div className='deFlexImgCards'>
                <div className='imgAbout'>
                    <img src={img} alt="" />
                </div>
                <div className='cardsText'>
                    <div className='cardText'>
                        <FontAwesomeIcon icon={faCheck} className='check' />
                        <p>
                            Pedidos organizados +  WhatsApp.
                        </p>
                    </div>
                    <div className='cardText'>
                        <FontAwesomeIcon icon={faCheck} className='check' />
                        <p>
                            Organiza tus productos en categorías y subcategorias.
                        </p>
                    </div>

                    <div className='cardText'>
                        <FontAwesomeIcon icon={faCheck} className='check' />
                        <p>
                            Agrega, edita o elimina artículos fácilmente.
                        </p>
                    </div>
                    <div className='cardText'>
                        <FontAwesomeIcon icon={faCheck} className='check' />
                        <p>
                            Muestra banners promocionales en la página principal.
                        </p>
                    </div>
                    <div className='cardText'>
                        <FontAwesomeIcon icon={faCheck} className='check' />
                        <p>
                            Administra usuarios y asigna roles.
                        </p>
                    </div>

                    <div className='cardText'>
                        <FontAwesomeIcon icon={faCheck} className='check' />
                        <p>
                            Utiliza códigos de descuento para ofrecer beneficios.
                        </p>
                    </div>



                    <div className='cardText'>
                        <FontAwesomeIcon icon={faCheck} className='check' />
                        <p>
                            Añade detalles de contacto para mejorar la comunicación.
                        </p>
                    </div>

                </div>
            </div>
            <h2 className='titleSec2'>Quickorder Plus</h2>
            <div className='deFlexImgCards'>

                <div className='cardsText'>
                    <div className='cardText'>
                        <FontAwesomeIcon icon={faCheck} className='check' />
                        <p>
                            Pedidos organizados por mesas +  WhatsApp.
                        </p>
                    </div>
                    <div className='cardText'>
                        <FontAwesomeIcon icon={faCheck} className='check' />
                        <p>
                            Organiza tus productos en categorías y subcategorias.
                        </p>
                    </div>

                    <div className='cardText'>
                        <FontAwesomeIcon icon={faCheck} className='check' />
                        <p>
                            Agrega, edita o elimina artículos fácilmente.
                        </p>
                    </div>
                    <div className='cardText'>
                        <FontAwesomeIcon icon={faCheck} className='check' />
                        <p>
                            Muestra banners promocionales en la página principal.
                        </p>
                    </div>
                    <div className='cardText'>
                        <FontAwesomeIcon icon={faCheck} className='check' />
                        <p>
                            Administra usuarios y asigna roles.
                        </p>
                    </div>

                    <div className='cardText'>
                        <FontAwesomeIcon icon={faCheck} className='check' />
                        <p>
                            Utiliza códigos de descuento para ofrecer beneficios.
                        </p>
                    </div>



                    <div className='cardText'>
                        <FontAwesomeIcon icon={faCheck} className='check' />
                        <p>
                            Añade detalles de contacto para mejorar la comunicación.
                        </p>
                    </div>


                </div>
                <div className='imgAbout'>
                    <img src={img2} alt="" />
                </div>
            </div>
        </div>
    )
}
