import React from 'react'
import './Realidad.css'
import img from '../../images/realidad.webp'
import BtnContact from '../BtnContact/BtnContact'
export default function Realidad() {
    return (
        <div className='Realidad'>
            <img src={img} alt="realidad" />

            <div className='RealidadText'>
                <h2 className='titleSec'>De idea a realidad</h2>
                <p>Nos enfocamos en <strong>crear tecnología para transformar el día a día de las empresas.</strong></p>
                <span>Formamos parte de su historia de transformación y marcamos un camino hacia el futuro.</span>
                <BtnContact />
            </div>

        </div>
    )
}
