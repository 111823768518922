import { React, useState } from 'react';
import './Planes.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar, faRocket, faCrown, faCheckCircle, faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import contact from '../contact';
import Modal from 'react-modal';
export default function Planes() {
    const [isOpen, setIsOpen] = useState(false);
    const [selectedPlanTitle, setSelectedPlanTitle] = useState('');

    const phone = contact[0].telefono;
    const handleWhatsappMessage = (planTitle) => {
        // Construimos el mensaje basado en los datos del formulario
        const serviciosSeleccionados = Object.entries(formData.servicios)
            .filter(([_, checked]) => checked)
            .map(([servicio]) => servicio.replace(/([A-Z])/g, ' $1').trim())
            .join(', ');

        const message = `
            *Hola! estoy interesado en el ${planTitle}*\n\n*Nombre y Apellido:* ${formData.nombre}\n\n*DNI o CUIT:* ${formData.dni}\n\n*Nombre del Local:* ${formData.nombreLocal}\n\n*Dirección del Local:* ${formData.direccionLocal}\n\n*Correo Electrónico:* ${formData.correo}\n\n*Teléfono Celular:* ${formData.telefono}\n\n*Servicios solicitados:* ${serviciosSeleccionados || 'Ninguno'}\n\n
        `;

        const whatsappUrl = `https://api.whatsapp.com/send?phone=${phone}&text=${encodeURIComponent(message)}`;

        window.open(whatsappUrl, '_blank');
    };
    const handleWhatsappMessage2 = (planTitle) => {

        const message = `Hola, estoy interesado en el ${planTitle} anual. ¿Podrías darme más detalles?`;
        const whatsappUrl = `https://api.whatsapp.com/send?phone=${phone}&text=${encodeURIComponent(message)}`;

        window.open(whatsappUrl, '_blank');
    };
    const plans = [
        {
            title: 'Plan Básico',
            dirigidoA: 'Pequeños negocios que quieren una presencia básica en línea para recibir pedidos sin muchas personalizaciones.',
            paginaDominio: 'Subdominio (ej. tucomida.stechev.com)',
            catalogoProductos: 'Hasta 20 productos',
            categoriasProductos: 'Limitado a 3 categorías',
            bannerPromocional: '1 banner estático de bienvenida',
            promocionesOfertas: 'Configuración básica de hasta 2 promociones',
            pedidos: 'Pedidos ilimitados',
            ventas: 'Sin comisiones por venta',
            soporte: 'Asistencia vía chat durante horario laboral',
            costoMensual: 15000,
            icon: faStar,
        },
        {
            title: 'Plan Medio',
            dirigidoA: 'Negocios medianos que buscan un mayor control de promociones y opciones de personalización para captar más clientes.',
            paginaDominio: 'Dominio propio (ej. tucomida.com)',
            catalogoProductos: 'Hasta 40 productos',
            categoriasProductos: 'Hasta 10 categorías',
            bannerPromocional: 'Hasta 3 banners.',
            promocionesOfertas: 'Configuración media de hasta 5 promociones',
            pedidos: 'Pedidos ilimitados',
            ventas: 'Sin comisiones por venta',
            soporte: 'Asistencia personalizada en horario extendido (incluye chat y correo)',
            costoMensual: 25000,
            icon: faRocket,
        },
        {
            title: 'Plan Pro',
            dirigidoA: 'Negocios grandes o con un volumen elevado de pedidos que desean una solución personalizada y optimizada para ventas online.',
            paginaDominio: 'Dominio propio (ej. tucomida.com)',
            catalogoProductos: 'Productos ilimitados',
            categoriasProductos: 'Categorías ilimitadas',
            bannerPromocional: 'Banners ilimitados.',
            promocionesOfertas: 'Promociones ilimitadas.',
            pedidos: 'Pedidos ilimitados',
            ventas: 'Sin comisiones por venta',
            soporte: 'Asistencia 24/7, soporte técnico avanzado, y acceso a un gestor de cuenta',
            costoMensual: 39000,
            icon: faCrown,
        },
    ];


    const [formData, setFormData] = useState({
        plan: '',
        nombre: '',
        dni: '',
        nombreLocal: '',
        direccionLocal: '',
        correo: '',
        telefono: '',
        servicios: {
            QuickOrder: false,
            QuickOrderPlus: false,
            otros: false,
        },
    });

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        if (type === 'checkbox') {
            setFormData((prev) => ({
                ...prev,
                servicios: {
                    ...prev.servicios,
                    [name]: checked,
                },
            }));
        } else {
            setFormData((prev) => ({
                ...prev,
                [name]: value,
            }));
        }
    };
    const handleSubmit = (e) => {
        e.preventDefault();
        handleWhatsappMessage(selectedPlanTitle);
    };

    return (
        <div className='Planes'>
            <h2 className='titleSec'>Nuestros Planes</h2>
            <p className='parradoSec'>Descubre cómo nuestro sistema facilita la administración de tus productos y la gestión de pedidos con un solo clic.</p>
            <div className="panes-container">
                {plans.map((plan, index) => {

                    return (
                        <div key={index} className="plan-card">
                            <FontAwesomeIcon icon={plan.icon} className="planIcono" />
                            <h3>{plan.title}</h3>
                            <h3>$ {plan.costoMensual?.toLocaleString('es-AR')} </h3>
                            <h4>Mensual</h4>

                            <button
                                className='btnPlan'
                                onClick={() => {
                                    setSelectedPlanTitle(plan.title);
                                    setIsOpen(true);
                                }}
                            >
                                Elegir plan
                            </button>
                            <span onClick={() => handleWhatsappMessage2(plan.title)}>Consultar por Plan Anual</span>
                            <hr />

                            <div className='deFlexPlan'>
                                <strong> <FontAwesomeIcon icon={faCheckCircle} className="checkIcon" /> Dirigido a:</strong>   <p> {plan.dirigidoA}</p>
                            </div>
                            <div className='deFlexPlan'>
                                <strong><FontAwesomeIcon icon={faCheckCircle} className="checkIcon" /> Página y Dominio:</strong>   <p>{plan.paginaDominio}</p>
                            </div>
                            <div className='deFlexPlan'>
                                <strong><FontAwesomeIcon icon={faCheckCircle} className="checkIcon" /> Catálogo de Productos:</strong>   <p>{plan.catalogoProductos}</p>
                            </div>
                            <div className='deFlexPlan'>
                                <strong><FontAwesomeIcon icon={faCheckCircle} className="checkIcon" /> Categorías de Productos:</strong>  <p> {plan.categoriasProductos}</p>
                            </div>

                            <div className='deFlexPlan'>
                                <strong><FontAwesomeIcon icon={faCheckCircle} className="checkIcon" /> Banner Promocional:</strong>   <p>{plan.bannerPromocional}</p>
                            </div>

                            <div className='deFlexPlan'>
                                <strong><FontAwesomeIcon icon={faCheckCircle} className="checkIcon" /> Promociones y Ofertas:</strong>   <p>{plan.promocionesOfertas}</p>
                            </div>
                            <div className='deFlexPlan'>
                                <strong><FontAwesomeIcon icon={faCheckCircle} className="checkIcon" /> Pedidos:</strong>   <p>{plan.pedidos}</p>
                            </div>
                            <div className='deFlexPlan'>
                                <strong><FontAwesomeIcon icon={faCheckCircle} className="checkIcon" /> Ventas:</strong>   <p>{plan.ventas}</p>
                            </div>

                            <div className='deFlexPlan'>
                                <strong><FontAwesomeIcon icon={faCheckCircle} className="checkIcon" /> Soporte:</strong>  <p> {plan.soporte}</p>
                            </div>

                            <Modal
                                isOpen={isOpen}
                                onRequestClose={() => setIsOpen(false)}
                                className="modalForm"
                                overlayClassName="overlay"
                            >
                                <div className="modal-content">
                                    <div className='HeaderModal'>
                                        <button onClick={() => setIsOpen(false)}>
                                            <FontAwesomeIcon icon={faArrowLeft} />
                                        </button>
                                        <h3>{selectedPlanTitle}</h3>

                                    </div>
                                    <form onSubmit={handleSubmit} className='form'>
                                        <fieldset id='fieldsetNone'>
                                            <legend>Plan:</legend>
                                            <input
                                                type="text"
                                                name="plan"
                                                value={selectedPlanTitle}
                                                onChange={handleChange}
                                                required
                                                disabled
                                            />
                                        </fieldset>
                                        <fieldset>
                                            <legend>Nombre y Apellido Titular:</legend>
                                            <input
                                                type="text"
                                                name="nombre"
                                                value={formData.nombre}
                                                onChange={handleChange}
                                                required
                                            />
                                        </fieldset>
                                        <fieldset>
                                            <legend>DNI o CUIT:</legend>
                                            <input
                                                type="number"
                                                name="dni"
                                                value={formData.dni}
                                                onChange={handleChange}
                                                required
                                            />
                                        </fieldset>
                                        <fieldset>
                                            <legend>Nombre del Local:</legend>
                                            <input
                                                type="text"
                                                name="nombreLocal"
                                                value={formData.nombreLocal}
                                                onChange={handleChange}
                                                required
                                            />
                                        </fieldset>
                                        <fieldset>
                                            <legend>Dirección del Local: </legend>
                                            <input
                                                type="text"
                                                name="direccionLocal"
                                                value={formData.direccionLocal}
                                                onChange={handleChange}
                                                required
                                            />
                                        </fieldset>
                                        <fieldset>
                                            <legend>Correo Electrónico:</legend>
                                            <input
                                                type="email"
                                                name="correo"
                                                value={formData.correo}
                                                onChange={handleChange}
                                                required
                                            />
                                        </fieldset>

                                        <fieldset>
                                            <legend>Teléfono Celular - Wsp:</legend>
                                            <input
                                                type="number"
                                                name="telefono"
                                                value={formData.telefono}
                                                onChange={handleChange}
                                                required
                                            />
                                        </fieldset>

                                        <fieldset id='fieldset100'>
                                            <legend>Servicios solicitados</legend>
                                            <div className='checksGrap'>
                                                <label>
                                                    <input
                                                        type="checkbox"
                                                        name="QuickOrder"
                                                        checked={formData.servicios.quickOrder}
                                                        onChange={handleChange}
                                                    />
                                                    QuickOrder
                                                </label>
                                                <label>
                                                    <input
                                                        type="checkbox"
                                                        name="QuickOrderPlus"
                                                        checked={formData.servicios.quickOrderPlus}
                                                        onChange={handleChange}
                                                    />
                                                    QuickOrder Plus
                                                </label>
                                                <label>
                                                    <input
                                                        type="checkbox"
                                                        name="otros"
                                                        checked={formData.servicios.otros}
                                                        onChange={handleChange}
                                                    />
                                                    Otros Sistemas
                                                </label>
                                            </div>
                                        </fieldset>

                                        <button type="submit">Enviar</button>
                                    </form>
                                </div>
                            </Modal>
                        </div>
                    );
                })}
            </div>


            <hr className='hrSection' />
        </div>
    );
}
