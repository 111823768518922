import React from 'react'
import BtnContact from '../BtnContact/BtnContact'
import './Solucion.css'
import contactData from '../contact';
import { Link as Anchor } from 'react-router-dom';
import logo from '../../images/logo.png';
export default function Solucion() {
    const contact = contactData[0];
    return (
        <div className='Solucion'>
            <hr className='hrSection' />
            <img src={logo} alt="" className='imgSec' />
            <h2 className='titleSec'>¿Estás listo para despegar?</h2>
            <div className='deFlexSolucion'>
                <Anchor to={`mailto:${contact.email}`} target="_blank">{contact.email}</Anchor> <span>|</span> <Anchor to={`tel:${contact.telefono}`} target="_blank">+{contact.telefono}</Anchor>
            </div>
            <div className='socials'>
                <Anchor to={contact.instagram} target="_blank"><i className='fa fa-instagram'></i></Anchor>
                <Anchor to={contact.linkedin} target="_blank"><i className='fa fa-linkedin'></i></Anchor>
                <Anchor to={contact.facebook} target="_blank"><i className='fa fa-facebook'></i></Anchor>
            </div>
            <BtnContact />
            <hr className='hrSection' />
        </div>
    )
}
