import React, { useEffect, useState, useRef } from 'react';
import Modal from 'react-modal';
import { Link as Anchor } from 'react-router-dom';
import logo from '../../images/logo.png';
import 'swiper/swiper-bundle.css';
import Profile from '../Profile/Profile'
import './Navbar.css'
import fondo from '../../images/fondo.png'
import { useMediaQuery } from '@react-hook/media-query';
export default function Navbar() {
    const [isOpen, setIsOpen] = useState(false);
    const isScreenLarge = useMediaQuery('(min-width: 900px)');

    return (
        <header>
            <nav>

                <Anchor to={`/`} className='logo'>

                    {isScreenLarge ? (
                        <img src={logo} alt="logo" />
                    ) : (
                        <img src={logo} alt="logo" />
                    )}
                </Anchor>

                <div className='deFLexNavs'>

                    <div className='enlaces'>
                        <Anchor to={`/`} onClick={() => {
                            document.querySelector('.heroContain').scrollIntoView({ behavior: 'smooth' });
                        }}>Inicio</Anchor>


                        <Anchor to={`/`} onClick={() => {
                            document.querySelector('.aboutContain').scrollIntoView({ behavior: 'smooth' });
                        }}>Nosotros</Anchor>

                        <Anchor to={`/`} onClick={() => {
                            document.querySelector('.aboutText a').scrollIntoView({ behavior: 'smooth' });
                        }}>Aplicativos</Anchor>
                        <Anchor to={`/`} onClick={() => {
                            document.querySelector('.Planes').scrollIntoView({ behavior: 'smooth' });
                        }}>Planes</Anchor>
                        <Anchor to={`/`} onClick={() => {
                            document.querySelector('.Contact').scrollIntoView({ behavior: 'smooth' });
                        }}>Contacto</Anchor>
                    </div>

                    <div className={`nav_toggle  ${isOpen && "open"}`} onClick={() => setIsOpen(!isOpen)}>
                        <span></span>
                        <span></span>
                        <span></span>
                    </div>
                </div>


                <Modal
                    isOpen={isOpen}
                    onRequestClose={() => setIsOpen(false)}
                    className="modalNav"
                    overlayClassName="overlay"
                >
                    <div className="modalNav-content">
                        <Profile />
                    </div>
                </Modal>

            </nav>
        </header>
    );
}
